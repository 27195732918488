import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import styled from '@emotion/styled';
import FunctinosTemplate from 'components/Templates/FunctinosTemplate';
import { FluidObject } from 'gatsby-image';
import { Helmet } from 'react-helmet';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface uxAnalysisProps {
  location: {
    pathname: string;
  };
  data: {
    logoNerdFactoryImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    topFunctionImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img1: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img2: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img3: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    imgInflow: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    imgPathPlot: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    thumbnail: {
      publicURL: string;
    };
  };
}

const uxAnalysis = ({ data, location }: uxAnalysisProps) => {
  const title = 'VODA | 기능 | 사용성(UX) 분석';
  const desc = '사용자의 생각, 의도를 이해할 수 있도록 VODA가 도와드립니다';
  return (
    <Component>
      <Helmet>
        <title>VODA | 기능 | 사용성(UX) 분석</title>
        <meta
          name="description"
          content="사용자의 생각, 의도를 이해할 수 있도록 VODA가 도와드립니다"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="<https://voda.nerdfactory.ai/functions/uxAnalysis>"
        />
        <meta property="og:image" content={data.thumbnail.publicURL} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content={title} />
        <meta
          name="naver-site-verification"
          content="062e2e63e40411827d007a80f9f6ca2b16af6702"
        />
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>
      <FunctinosTemplate
        location={location}
        title={'고객의 시선으로 서비스 분석'}
        header={{
          main: '마우스의 움직임으로 웹 서비스를\\n사용하는 고객의 시선과 의도를\\n포착하고, 고객이 원하는 것을\\n파악할 수 있습니다.',
          sub: '시선의 흐름을 VODA',
        }}
        isAIvory={false}
        functionData={[
          {
            IllustImg: data.img1.childImageSharp.fluid,
            descData: {
              title:
                '고객이 웹서비스에서 어떤\\n콘텐츠에 관심을 표현하는지\\n마우스 클릭 데이터로\\n포착합니다.',
              list: [
                '1. 클릭 분포 및 클릭 집중 시각화',
                '2. 페이지 내 요소의 클릭 관련 지표',
                '- 클릭 순위',
                '- 클릭 횟수',
                '- 클릭 비율',
              ],
            },
            engTitle: 'CLICK HEATMAP',
            korTitle: '고객이 관심있는 콘텐츠',
            isBackGround: true,
            isDescLeft: false,
            width: 800,
            key: '0',
          },
          {
            IllustImg: data.img2.childImageSharp.fluid,
            descData: {
              title:
                '웹 페이지에서 콘텐츠의 배치를\\n달리함으로써, 고객의 콘텐츠\\n주목도를 향상시키고\\n체류시간을 증가시킬 수 있습니다.',
              list: ['1. 페이지 높이에 따른 평균 체류시간', '2. 높이별 도달률'],
            },
            engTitle: 'SCROLL HEATMAP',
            korTitle: '페이지에서 고객의 콘텐츠 집중도',
            isBackGround: false,
            isDescLeft: true,
            width: 800,
            key: '1',
          },
          {
            IllustImg: data.img3.childImageSharp.fluid,
            descData: {
              title:
                '페이지 플로우는 고객이\\n어디에서 와서 어디로 가는지를\\n알려줍니다.\\n고객의 여정을 이해하면,\\n선별적인 맞춤형 트래픽 상승 전략이\\n가능합니다.',
              list: ['1. 유입(방문) 경로', '2. 유출(이탈) 경로'],
            },
            engTitle: 'PAGE FLOW',
            korTitle: '고객이 어디서 와서 어디로 가는지',
            isBackGround: true,
            isDescLeft: false,
            width: 800,
            key: '2',
          },
          {
            IllustImg: data.imgInflow.childImageSharp.fluid,
            descData: {
              title:
                '유입 경로 별로 고객의 유입 비율\\n뿐만 아니라, 방문을 위한\\n검색 키워드 분석으로 웹 서비스의\\n세분화된 고객 증가 전략 수립이\\n가능합니다.',
              list: [
                '1. 일별 유입경로 분석',
                '2. 직접, 검색, 소셜, 추천 유입에 대한 통계 분석',
                '3. 각 유입에 대한 상세 정보 제공',
              ],
            },
            engTitle: 'INFLOWPATH',
            korTitle: '상세한 유입경로 분석으로\\n세분화된 고객 증가 전략 수립',
            isBackGround: true,
            isDescLeft: true,
            width: 800,
            key: '3',
          },
          {
            IllustImg: data.imgPathPlot.childImageSharp.fluid,
            descData: {
              title:
                '메인 페이지부터 게시물까지\\n웹 서비스 내의 모든 페이지에 대한\\n고객의 네비게이션 흐름을 분석해서\\n웹 서비스 효율화 전략을\\n제시합니다.',
              list: ['전체 페이지의 유입, 전환, 이탈 분석'],
            },
            engTitle: 'PATHPLOT',
            korTitle: '고객의 웹 서비스 사용 흐름 시각화',
            isBackGround: true,
            isDescLeft: false,
            width: 800,
            key: '4',
          },
        ]}
        img={data.topFunctionImg.childImageSharp.fluid}
        logoNerdFactoryImg={data.logoNerdFactoryImg.childImageSharp.fluid}
      />
    </Component>
  );
};

export default uxAnalysis;

export const queryData = graphql`
  query {
    logoNerdFactoryImg: file(name: { eq: "logo-nerd-factory@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    topFunctionImg: file(name: { eq: "img-top-function@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img1: file(name: { eq: "img-clickHeatmap-v2" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img2: file(name: { eq: "img-scroll-v2" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img3: file(name: { eq: "img-pageflow" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgInflow: file(name: { eq: "img-inflowpath" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgPathPlot: file(name: { eq: "img-pathplot" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thumbnail: file(name: { eq: "thumbnail-1280-720" }) {
      publicURL
    }
  }
`;
